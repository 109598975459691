import React, {Component} from 'react';
import Checkmark from "../../svg/Checkmark.svg";
import {Modal} from "react-bootstrap";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default class DadosProdutos extends Component {

    constructor(props) {
        super(props);
        this.handleModalPdf = this.handleModalPdf.bind(this);
        this.onLoadDocumentSuccess = this.onLoadDocumentSuccess.bind(this);
        this.renderPdf = this.renderPdf.bind(this);
        this.state = ({showModalPdf: false, numPages: null, setNumPages: null})
    }

    renderPdf() {
        let tableItems = [];
        if (this.props.props.certificado !== undefined && this.props.props.certificado !== null) {
            tableItems.push(
                <input type="button" className="btn btn-primary" onClick={this.handleModalPdf} value="Certificado do Produto" />
            );
            tableItems.push(
                <Modal size="lg" show={this.state.showModalPdf} onHide={this.handleModalPdf}>
                    <Modal.Header closeButton>
                        <Modal.Title>Certificado do Produto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{overflowX: "scroll", msOverflowX: "scroll"}}>
                        <Document loading="Carregando certificado. Aguarde..." renderMode="canvas" file={"https:" + this.props.props.certificado.file.url} onLoadSuccess={this.onLoadDocumentSuccess}>
                            {Array.from(new Array(this.state.numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    </Modal.Body>
                </Modal>
            );
        } else {
            tableItems.push(<></>);
        }
        return tableItems;
    }

    handleModalPdf() {
        this.setState(prevCheck => ({showModalPdf: !prevCheck.showModalPdf}))
    }

    onLoadDocumentSuccess({numPages: nextNumPages}) {
        this.setState({setNumPages: nextNumPages});
    }

    render() {

        let cores = "";
        if (this.props.props.cores !== null) {
            for (let i = 0; i < this.props.props.cores.length; i++) {
                cores = cores + this.props.props.cores[i];
                cores = cores + (i === (this.props.props.cores.length - 2) ? " e " : (i === (this.props.props.cores.length - 1) ? "." : ", "));
            }
        }

        return(
            <>
                <div className="container iberica-dados-produtos-container">
                    <div className="row">
                        <h1>{this.props.props.nomeProduto}</h1>
                        {this.props.props.acondicionamento !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Acondicionamento:</b> {this.props.props.acondicionamento}</p>}
                        {this.props.props.aplicao !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Aplicação:</b> {this.props.props.aplicao}</p>}
                        {this.props.props.cobertura !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Cobertura:</b> {this.props.props.cobertura}</p>}
                        {this.props.props.condutor !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Condutor:</b> {this.props.props.condutor}</p>}
                        {this.props.props.cores !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Cores:</b> {cores}</p>}
                        {this.props.props.designcacaoProduto !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Designação do Produto:</b> {this.props.props.designcacaoProduto}</p>}
                        {this.props.props.isolacao !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Isolação do Produto:</b> {this.props.props.isolacao}</p>}
                        {this.props.props.normasAplicaveisDeProduto !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Normas Aplicáveis de Produto:</b> {this.props.props.normasAplicaveisDeProduto}</p>}
                        {this.props.props.normaDeInstalacao !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Normas de Instalação do Produto:</b> {this.props.props.normaDeInstalacao}</p>}
                        {this.props.props.norma !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Normas Regulamentadoras:</b> {this.props.props.norma}</p>}
                        {this.props.props.recomendacoesDeUso !== null && <p className="iberica-p-svg-produtos"><Checkmark className="iberica-svg-produtos" /> <b>Recomendações de Uso:</b> {this.props.props.recomendacoesDeUso}</p>}
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            {
                                this.renderPdf()[0]
                            }
                        </div>
                    </div>
                </div>

                {
                    this.renderPdf()[1]
                }
            </>
        )
        
    }
}

