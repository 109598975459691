import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DadosProdutos from '../components/Produtos/DadosProdutos';
import EspecificacaoProdutos from '../components/Produtos/EspecificacaoProdutos';
import "../styles.scss";
import ProdutoSEO from '../components/Produtos/ProdutoSEO';
import ContatoProdutos from "../components/Produtos/ContatoProdutos";
import HeroImageProduto from "../components/Produtos/HeroImageProduto";


class ProdutoPage extends Component {

    renderSpecs() {
        if (this.props.data.specs !== undefined && this.props.data.specs !== null) {
            return(<EspecificacaoProdutos props={this.props.data.specs} />);
        } else {
            return(<></>);
        }
    }

    render() {
        return(
            <>
                <ProdutoSEO props={this.props.data.product} />
                <Header />
                <HeroImageProduto props={this.props.data} />
                <DadosProdutos props={this.props.data.product} />
                {this.renderSpecs()}
                <ContatoProdutos props={this.props.data.product.urlProduto} />
                <Footer />
            </>
            )
    }
}

export const query = graphql`
    query($urlProduto:String!) {
        product: contentfulProduto(urlProduto:{eq:$urlProduto}) {
            id
            urlProduto
            imagemProduto {
              gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              file {
                url
              }
            }
            videoProduto {
              file {
                url
                contentType
              }
            }
            nomeProduto
            designcacaoProduto
            cores
            cobertura
            condutor
            aplicao
            acondicionamento
            isolacao
            norma
            normaDeInstalacao
            normasAplicaveisDeProduto
            recomendacoesDeUso    
            createdAt
            updatedAt
            certificado {
                file {
                    contentType
                    url
                }
            }
        }
        specs: contentfulEspecificacoesDosProdutos(produto: {urlProduto: {eq: $urlProduto}}) {
            secaoNominal
            formacaoDoCondutor
            diametroNominalDoCondutor
            espessuraNominalDaIsolacao
            espessuraNominalDaCobertura
            diametroExternoNominal
            dimensaoExternaNominal
            classeDeEncordamento
            numeroDeFios
            pesoTotal
        }
        video: contentfulProduto(urlProduto: {eq: $urlProduto}) {
            videoProduto {
                file {
                    url
                    contentType
                  }
            }
        }
        poster: contentfulProduto(urlProduto: {eq: $urlProduto}) {
            imagemProduto {
                gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                file {
                    url
                    contentType
                }
            }
        }
    }
`

export default ProdutoPage