import React, { Component } from "react";

class DadosProdutos extends Component {
    renderTable() {
        if (this.props.props !== undefined && this.props.props !== null) {
            let tableItems = [];
            for (let i = 0; i < this.props.props.secaoNominal.length; i++) {
                tableItems.push(
                    <tr key={i} style={{textAlign: "center"}}>
                        {this.props.props.secaoNominal !== undefined && this.props.props.secaoNominal !== null && <td>{this.props.props.secaoNominal[i]}</td>}
                        {this.props.props.formacaoDoCondutor !== undefined && this.props.props.formacaoDoCondutor !== null && <td>{this.props.props.formacaoDoCondutor[i]}</td>}
                        {this.props.props.diametroNominalDoCondutor !== undefined && this.props.props.diametroNominalDoCondutor !== null && <td>{this.props.props.diametroNominalDoCondutor[i]}</td>}
                        {this.props.props.espessuraNominalDaIsolacao !== undefined && this.props.props.espessuraNominalDaIsolacao !== null && <td>{this.props.props.espessuraNominalDaIsolacao[i]}</td>}
                        {this.props.props.espessuraNominalDaCobertura !== undefined && this.props.props.espessuraNominalDaCobertura !== null && <td>{this.props.props.espessuraNominalDaCobertura[i]}</td>}
                        {this.props.props.diametroExternoNominal !== undefined && this.props.props.diametroExternoNominal !== null && <td>{this.props.props.diametroExternoNominal[i]}</td>}
                        {this.props.props.dimensaoExternaNominal !== undefined && this.props.props.dimensaoExternaNominal !== null && <td>{this.props.props.dimensaoExternaNominal[i]}</td>}
                        {this.props.props.classeDeEncordamento !== undefined && this.props.props.classeDeEncordamento !== null && <td>{this.props.props.classeDeEncordamento[i]}</td>}
                        {this.props.props.nmeroDeCondutores !== undefined && this.props.props.nmeroDeCondutores !== null && <td>{this.props.props.nmeroDeCondutores[i]}</td>}
                        {this.props.props.numeroDeFios !== undefined && this.props.props.numeroDeFios !== null && <td>{this.props.props.numeroDeFios[i]}</td>}
                        {this.props.props.pesoTotal !== null && this.props.props.pesoTotal !== undefined && <td>{this.props.props.pesoTotal[i]}</td>}
                    </tr>
                )
            }
            return tableItems;
        }
    }
    
    render() {
        if (this.props.props === undefined || this.props.props === null) {
            return(<></>)
        } else {
            return(
                <div className="container">
                    <div className="row" style={{overflowX: "scroll", msOverflowX: "scroll"}}>
                        <h4 className="text-center">Especificações Detalhadas</h4>
                        <table className="table table-striped">
                            <thead className="thead-light">
                                <tr style={{textAlign: "center"}}>
                                    {this.props.props.secaoNominal !== undefined && this.props.props.secaoNominal !== null && <th scope="col">Seção Nominal (mm²)</th>}
                                    {this.props.props.formacaoDoCondutor !== undefined && this.props.props.formacaoDoCondutor !== null && <th scope="col">Formação do Condutor</th>}
                                    {this.props.props.diametroNominalDoCondutor !== undefined && this.props.props.diametroNominalDoCondutor !== null && <th scope="col">Diâmetro Nominal do Condutor (mm)</th>}
                                    {this.props.props.espessuraNominalDaIsolacao !== undefined && this.props.props.espessuraNominalDaIsolacao !== null && <th scope="col">Espessura Nominal da Isolação (mm)</th>}
                                    {this.props.props.espessuraNominalDaCobertura !== undefined && this.props.props.espessuraNominalDaCobertura !== null && <th scope="col">Espessura Nominal da Cobertura (mm)</th>}
                                    {this.props.props.diametroExternoNominal !== undefined && this.props.props.diametroExternoNominal !== null && <th scope="col">Diâmetro Externo Nominal</th>}
                                    {this.props.props.dimensaoExternaNominal !== undefined && this.props.props.dimensaoExternaNominal !== null && <th scope="col">Dimensão Externa Nominal</th>}
                                    {this.props.props.classeDeEncordamento !== undefined && this.props.props.classeDeEncordamento !== null && <th scope="col">Classe de Encordamento</th>}
                                    {this.props.props.nmeroDeCondutores !== undefined && this.props.props.nmeroDeCondutores !== null && <th scope="col">Número de Condutores</th>}
                                    {this.props.props.numeroDeFios !== undefined && this.props.props.numeroDeFios !== null && <th scope="col">Número de Fios</th>}
                                    {this.props.props.pesoTotal !== undefined && this.props.props.pesoTotal !== null && <th scope="col">Peso Total (kg/100 m)</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

    }
}

export default DadosProdutos