import React from 'react';
import {Helmet} from 'react-helmet';

function ProdutoSEO(props) {

    const metaDescription = "Confira o produto "+props.props.nomeProduto + " na Cia. Ibérica.";
    const url = "https://www.ciaiberica.com.br/"+props.props.urlProduto + "/";


    return(
        <Helmet>
            <html lang="pt-BR" amp />
            <meta charSet="UTF-8" />
            <title>{props.props.nomeProduto} | Produtos Cia. Ibérica</title>
            <meta name="description" content={metaDescription} />
            <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
            <link rel="canonical" href={url} />
            <meta property="og:locale" content="pt-BR" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={props.props.nomeProduto + " | Produtos Cia. Ibérica"} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={url} />
            <meta property="og:sitename" content="Cia. Ibérica" />
            <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
            <meta property="article:published_time" content={props.props.createdAt} />
            <meta property="article:modified_time" content={props.props.updatedAt} />
            <meta property="og:image" content={props.props.imagemProduto.file.url} />
        </Helmet>
    )
}

export default ProdutoSEO