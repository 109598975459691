import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default class ContatoProdutos extends Component {

    setRecaptcha(value) {

    }
    

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        for (let i = 0; i < event.target.length; i++) {

        }

        event.target.reset();

    }
    render() {
        return(
            <div className="row iberica-contato-row">
                <div className="container">
                    <div className="col-sm-12" style={{textAlign: "center"}}>
                        <h2>Entre em Contato</h2>
                    </div>
                    <div className="container">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="name">Nome:</label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder="Insira seu nome" required />
                                    </div>                                        
                                </div>
                            </div> 
                            <div className="row form-row">
                                <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="email">E-mail: </label>
                                            <input className="form-control" required type="email" name="email" placeholder="Insira seu e-mail" id="email" />  
                                            <div className="form-text">Não compartilharemos seu e-mail com ninguém.</div>                                  
                                        </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="phone">Telefone: </label>
                                            <input className="form-control" required type="text" name="phone" placeholder="(11) 91234-5678 ou (11) 1234-5678" id="phone" />  
                                            <div className="form-text">Insira apenas números.</div>                                  
                                        </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="city">Cidade e Estado: </label>
                                            <input className="form-control" required type="text" placeholder="Insira sua Cidade e Estado aqui." name="city" id="city" />  
                                            <div className="form-text">Exemplo de preenchimento: São Paulo/SP.</div>                                  
                                        </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="zip">CEP: </label>
                                            <input className="form-control" required type="number" placeholder="01234-567" name="zip" id="zip" />  
                                            <div className="form-text">Insira apenas números.</div>                                  
                                        </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                    <p>Gostaria de receber um catálogo de nossos produtos?</p>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wantscatalog" id="wantscatalog" />
                                        <label htmlFor="wantscatalog">Sim, desejo receber o catálogo de produtos da Ibérica.</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="doesntwantcatalog" id="doesntwantcatalog" />
                                        <label htmlFor="doesntwantcatalog">Não, não desejo receber o catálogo de produtos da Ibérica.</label>

                                    </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                <p>Você já tem um vendedor em contato?</p>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wantsseller" id="wantsseller" />
                                        <label htmlFor="wantsseller">Sim, já tenho um vendedor em contato.</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="doesnthavebutwantsseller" id="doesnthavebutwantsseller" />
                                        <label htmlFor="doesnthavebutwantsseller">Não, mas gostaria de ter um vendedor em contato.</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="doesnthaveanddontwantseller" id="doesnthaveanddontwantseller" />
                                        <label htmlFor="doesnthaveanddontwantseller">Não, e não tenho interesse de ter um vendedor em contato.</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                <p>Você precisa de amostras?</p>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wantssamples" id="wantssamples" />
                                        <label htmlFor="wantssamples">Sim, preciso de amostras.</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="doesntwantsamples" id="doesntwantsamples" />
                                        <label htmlFor="doesntwantsamples">Não, não preciso de amostras.</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="tellus">Diga-nos o que está procurando.</label>
                                        <textarea className="form-control" name="tellus" id="tellus" required as="textarea" rows={3} placeholder="Insira sua mensagem aqui." />
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" value={this.props.props} id="productid" name="productid" />
                            <div className="row form-row">
                                <div className="col-sm-12">
                                    <ReCAPTCHA sitekey="6LdwuxQfAAAAABCNjhvQNLc-lPHq6Y9wBZAHlJX7" hl="pt-BR" onChange={this.setRecaptcha} />
                                </div>
                            </div>
                            <div className="row form-row">
                                <div className="col-sm-12">
                                    <input className='btn btn-primary' type="submit" value="Enviar formulário" />
                                    
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}